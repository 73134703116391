import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // French locale
import relativeTime from 'dayjs/plugin/relativeTime';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState, useEffect, useRef } from 'react';
import FertilityTestModal from '../components/fertility-test/modal';
// Layout
import Layout from '../components/layout';
// React Helmet SEO
import SEO from '../components/seo';
import PackEssentiel from '../components/static/pack-essentiel';
// Component: ProgramDescription
import ProgramDescription from '../components/static/program-description';
import ProgramOptions from '../components/static/program-options';
// Component: Tips
import Tips from '../components/static/tips';
// Component: Reassurance BeMum
import Reassurance from '../components/static/rea';
import Testimonials from '../components/static/testimonials';
import Link from '../components/utils/link';
import * as style from '../modules/index.module.scss';
import PrescriptionPopup from '../components/prescription-popup/prescription-popup';
import MedicalPartners from '../components/static/medical-partners';
import KeyFigures from '../components/static/key-figures';
import HelpProgram from '../components/repeatable/helpProgram';
import DietaryCart from '../components/repeatable/dietaryCart';
import ButtonSeeSomething from '../components/repeatable/buttonSeeSomething';
import { TrustPilotBox } from 'gatsby-plugin-trustpilot-trustbox';

dayjs.locale('fr'); // Use french
dayjs.extend(relativeTime); // Relative time

function IndexPage({ data }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    seo,
    title,
    content,
    choices,
    image,
    arrow,
    star,
    star_empty,
    button,
    rea,
    sticky,
    calendly,
    partners,
    working,
    call,
  } = data.pageIndexYaml;
  const totalSlides = Math.ceil(partners.length / 4);
  const carouselRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (totalSlides + 1));
  };

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex >= totalSlides) {
        carouselRef.current.style.transition = 'none';
        setCurrentIndex(0);
        setTimeout(() => {
          carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
        }, 50);
      } else if (currentIndex < 0) {
        carouselRef.current.style.transition = 'none';
        setCurrentIndex(totalSlides - 1);
        setTimeout(() => {
          carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
        }, 50);
      }
    };

    const carousel = carouselRef.current;
    carousel.addEventListener('transitionend', handleTransitionEnd);
    return () => carousel.removeEventListener('transitionend', handleTransitionEnd);
  }, [currentIndex, totalSlides]);

  const slides = [...partners.slice(-4), ...partners, ...partners.slice(0, 4)];

  const [modalOpen, setModalOpen] = useState(false);
  const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeCalendlyModal = () => {
    setCalendlyModalOpen(false);
  };

  return (
    <Layout withBanner>
      <SEO title={seo.title} description={seo.description} canonical={seo.canonical} />
      <PrescriptionPopup modalOpen={calendlyModalOpen} closeModal={closeCalendlyModal} />
      <FertilityTestModal modalOpen={modalOpen} closeModal={closeModal} />

      <header className={`${style.header}`}>
        <div className={style.header_wrapper}>
          <GatsbyImage className={style.header_image} image={image.childImageSharp.gatsbyImageData} alt={title} />
          <div className={style.header_text}>
            <h1 className={style.header_title} dangerouslySetInnerHTML={{ __html: title }} />
            <p className={style.header_subtitle}>{content}</p>
            <Link className={style.Btn} to={button.link}>
              {button.text}
            </Link>
          </div>
        </div>
      </header>

      <TrustPilotBox
        locale="fr-FR"
        defaultRevueURL="https://fr.trustpilot.com/review/bemum.co"
        minReviewCount="10"
        styleHeight="40px"
      />
      <Testimonials />

      {/* Carousel */}
      <div className={style.carousel}>
        <div className={style.carouselText}>et eux aussi !</div>
        <div
          className={style.carouselInner}
          ref={carouselRef}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides.map((partner, index) => (
            <div className={style.carouselItem} key={index}>
              <a href={partner.link} target="_blank">
                <GatsbyImage image={partner.icon.childImageSharp.gatsbyImageData} alt={partner.alt} />
              </a>
            </div>
          ))}
        </div>
      </div>

      <section className={style.programmeContainer}>
        <h2 className={style.bestSellers}>DÉCOUVREZ NOS BESTSELLERS :</h2>
        <div className={style.supplementsContainer}>
          {choices.map((choice) => (
            <DietaryCart choice={choice} star={star} starEmpty={star_empty} />
          ))}
        </div>
        <ButtonSeeSomething text={'Découvrir nos programmes'} />
      </section>

      <HelpProgram data={working} />

      <MedicalPartners />
      <KeyFigures />

      <section className={style.callSection}>
        <div className={style.callSectionImage}>
          <img src={call.image.publicURL} alt="call" />
        </div>
        <p dangerouslySetInnerHTML={{ __html: call.description }} className={style.callSectionDescription} />
      </section>

      {/* Reassurance */}
      <Reassurance />

      <div className={style.tipsCustom}>
        <Tips page="homepage" />
      </div>

      <a className={style.sticky} onClick={() => setCalendlyModalOpen(true)}>
        <span>J'ai une prescription médicale</span>
        <img src={sticky.publicURL} alt="prescription" height="30px" />
      </a>

      <a
        className={style.calendly}
        href="https://calendly.com/bemum/15min?month=2024-11"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>RDV Tél avec BeMum</span>
        <img src={calendly.publicURL} alt="calendly" height="70px" />
      </a>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  {
    pageIndexYaml {
      title
      seo {
        title
        description
        canonical
      }
      content
      image {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
      arrow {
        publicURL
      }
      star {
        publicURL
      }
      star_empty {
        publicURL
      }
      button {
        text
        link
      }
      rea {
        title
        text
        button {
          text
          link
        }
      }
      sticky {
        publicURL
      }
      calendly {
        publicURL
      }
      partners {
        alt
        icon {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        link
      }
      choices {
        id
        image {
          publicURL
        }
        title
        description
        cart
        program_type
      }
      working {
        title
        description
        items {
          image {
            publicURL
          }
          alt
          title
          description
        }
      }
      call {
        image {
          publicURL
        }
        description
      }
    }
  }
`;
