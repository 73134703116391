// extracted by mini-css-extract-plugin
export var background = "key-figures-module--background--PE-e+";
export var categories = "key-figures-module--categories--qnK0l";
export var category = "key-figures-module--category--s56VS";
export var circle = "key-figures-module--circle--3JMWc";
export var figure = "key-figures-module--figure--62UJO";
export var figures = "key-figures-module--figures--pexed";
export var fillProgress = "key-figures-module--fillProgress--fMAfs";
export var keyFigures = "key-figures-module--keyFigures--i-k8R";
export var progress = "key-figures-module--progress--QiYxu";
export var text = "key-figures-module--text--X3klW";
export var title = "key-figures-module--title--LBieU";