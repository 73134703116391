import React, { useEffect, useState, useRef } from 'react';
import * as style from './modules/key-figures.module.scss';

const KeyFigures = () => {
  const figures = [
    {
      title: 'GROSSESSE CLINIQUE',
      data: [
        { percentage: 19, label: 'SANS', description: 'Le régime profertilité', color: '#21254A' },
        { percentage: 72, label: 'AVEC', description: 'Le régime profertilité', color: '#E286B2' },
      ],
    },
    {
      title: 'NAISSANCES VIVANTES',
      data: [
        { percentage: 26, label: 'SANS', description: 'Le régime profertilité', color: '#21254A' },
        { percentage: 85, label: 'AVEC', description: 'Le régime profertilité', color: '#E286B2' },
      ],
    },
  ];

  const [visible, setVisible] = useState(false);
  const [percentages, setPercentages] = useState(figures.map((figure) => figure.data.map(() => 0)));
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      },
      { threshold: 0.6 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (visible) {
      figures.forEach((figure, idx) => {
        figure.data.forEach((item, subIdx) => {
          let start = 0;
          const end = item.percentage;
          const increment = Math.ceil(end / 50);
          const interval = setInterval(() => {
            start += increment;
            setPercentages((prev) => {
              const newPercentages = [...prev];
              newPercentages[idx][subIdx] = Math.min(start, end);
              return newPercentages;
            });
            if (start >= end) clearInterval(interval);
          }, 20);
        });
      });
    }
  }, [visible]);

  const calculateOffset = (percentage) => {
    const radius = 45;
    const circumference = 2 * Math.PI * radius;
    return circumference - (percentage / 100) * circumference;
  };

  return (
    <section ref={sectionRef} className={style.keyFigures}>
      <h2 className={style.title}>QUELQUES CHIFFRES CLÉS</h2>
      <div className={style.categories}>
        {figures.map((figure, idx) => (
          <div key={idx} className={style.category}>
            <h3>{figure.title}</h3>
            <div className={style.figures}>
              {figure.data.map((item, subIdx) => (
                <div key={subIdx} className={style.figure}>
                  <div className={style.circle}>
                    <svg width="120" height="120">
                      <circle className={style.background} cx="60" cy="60" r="45" />
                      <circle
                        className={`${style.progress} ${visible ? style.animate : ''}`}
                        cx="60"
                        cy="60"
                        r="45"
                        stroke={item.color}
                        strokeDasharray="282.6"
                        strokeDashoffset={calculateOffset(percentages[idx][subIdx])}
                      />
                    </svg>
                    <div className={style.text} style={{ color: item.color }}>
                      {percentages[idx][subIdx]}%
                    </div>
                  </div>
                  <div className={style.caption}>
                    <p className={style.highlight}>{item.label}</p>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KeyFigures;
