import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as styles from './modules/testimonials.module.scss';
const ButtonGroup = ({ arrow, next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;

  return (
    <div className={styles.custom_button_group}>
      <button className={currentSlide === 0 ? `${styles.hide}` : ''} onClick={() => previous()}>
        <img className={styles.arrow_left} height="15" src={arrow.publicURL} alt="left" />
      </button>
      <button className={currentSlide + slidesToShow === totalItems ? `${styles.hide}` : ''} onClick={() => next()}>
        <img className={styles.arrow_right} height="15" src={arrow.publicURL} alt="right" />
      </button>
    </div>
  );
};
function Testimonial({ name, avatar, text, stars }) {
  return (
    <div className={styles.testimonial_card}>
      <div className={styles.avatar_wrapper}>
        <GatsbyImage image={avatar} alt={name} className={styles.avatar} />
      </div>
      <div className={styles.testimonial_content}>
        <h4 className={styles.testimonial_name}>{name}</h4>
        <p className={styles.testimonial_text}>{text}</p>
        <div className={styles.stars}>
          <div className={styles.stars}>
            {[...Array(5)].map((_, i) => (
              <div key={i} className={styles.star}>
                {i < Math.floor(stars) && <span className={styles.star_filled}>★</span>}
                {i === Math.floor(stars) && stars % 1 !== 0 && <span className={styles.star_half}>★</span>}
                {i >= Math.ceil(stars) && <span className={styles.star_empty}>★</span>}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function Testimonials() {
  const { allStaticNewTestimonialsYaml, arrow } = useStaticQuery(graphql`
    query {
      allStaticNewTestimonialsYaml {
        edges {
          node {
            name
            text
            avatar {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
            }
            stars
          }
        }
      }
      arrow: file(relativePath: { eq: "arrows/left.svg" }) {
        publicURL
      }
    }
  `);

  const testimonials = allStaticNewTestimonialsYaml.edges.map((edge) => edge.node);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className={styles.testimonials_section}>
      <h3 className={styles.title}>Elles recommandent à 100%</h3>
      <Carousel
        responsive={responsive}
        arrows={false}
        infinite
        autoPlay
        customButtonGroup={<ButtonGroup arrow={arrow} />}
        autoPlaySpeed={5000}
        minimumTouchDrag={20}
        swipeable
        draggable
        pauseOnHover
        containerClass={styles.carousel_container}
        itemClass={styles.carousel_item}
      >
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            name={testimonial.name}
            text={testimonial.text}
            stars={testimonial.stars}
            avatar={testimonial.avatar.childImageSharp.gatsbyImageData}
          />
        ))}
      </Carousel>
    </section>
  );
}

export default Testimonials;
