// extracted by mini-css-extract-plugin
export var Btn = "index-module--Btn---30X7";
export var bestSellers = "index-module--bestSellers--DF6Ew";
export var calendly = "index-module--calendly--Ttfmo";
export var callSection = "index-module--callSection--F4veX";
export var callSectionDescription = "index-module--callSectionDescription--iE1Da";
export var callSectionImage = "index-module--callSectionImage--2PIAe";
export var carousel = "index-module--carousel--ZhVk5";
export var carouselInner = "index-module--carouselInner--kn2dg";
export var carouselItem = "index-module--carouselItem--eOCh9";
export var carouselText = "index-module--carouselText--l2qz9";
export var container = "index-module--container--zvDTO";
export var containerMobile = "index-module--containerMobile--hvDLv";
export var description = "index-module--description--KH8yn";
export var descriptionMobile = "index-module--descriptionMobile--ne78m";
export var header = "index-module--header--Wrrtt";
export var header_image = "index-module--header_image--WZM94";
export var header_subtitle = "index-module--header_subtitle--gBOPm";
export var header_text = "index-module--header_text--i3EdK";
export var header_title = "index-module--header_title--qhgPm";
export var header_wrapper = "index-module--header_wrapper--xIJfv";
export var imagePartner0 = "index-module--imagePartner0--2EAh9";
export var imagePartner1 = "index-module--imagePartner1--LJtN5";
export var imagePartner2 = "index-module--imagePartner2--MkNHP";
export var imagePartner3 = "index-module--imagePartner3--ObqQs";
export var imagePartnerMobile = "index-module--imagePartnerMobile--Ll-+3";
export var partener = "index-module--partener--BmXQy";
export var partenerMobile = "index-module--partenerMobile--0z5Pv";
export var partenerTitle = "index-module--partenerTitle--QjE3G";
export var partnersContainer = "index-module--partnersContainer--qqtZR";
export var partnersContainerMobile = "index-module--partnersContainerMobile--otdah";
export var programmeContainer = "index-module--programmeContainer--prafL";
export var rea = "index-module--rea--DXB4O";
export var rea_text = "index-module--rea_text--ILcef";
export var rea_title = "index-module--rea_title--M94mU";
export var sticky = "index-module--sticky--lSDiL";
export var supplementsContainer = "index-module--supplementsContainer--J8fiS";
export var title = "index-module--title--hwh5w";
export var titleMobile = "index-module--titleMobile--hQjyi";