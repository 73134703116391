// extracted by mini-css-extract-plugin
export var arrow_left = "testimonials-module--arrow_left--lRbcO";
export var arrow_right = "testimonials-module--arrow_right--yxR5E";
export var avatar = "testimonials-module--avatar--F82Jp";
export var avatar_wrapper = "testimonials-module--avatar_wrapper--fZUPA";
export var carousel_container = "testimonials-module--carousel_container--1U+AP";
export var carousel_item = "testimonials-module--carousel_item--K3l6e";
export var custom_button_group = "testimonials-module--custom_button_group--6teil";
export var star = "testimonials-module--star--XKU5V";
export var star_empty = "testimonials-module--star_empty--ChYOI";
export var star_filled = "testimonials-module--star_filled--YQOvl";
export var star_half = "testimonials-module--star_half--1JCxa";
export var stars = "testimonials-module--stars--+zKhr";
export var testimonial_card = "testimonials-module--testimonial_card--Ju1co";
export var testimonial_content = "testimonials-module--testimonial_content--pfS+X";
export var testimonial_name = "testimonials-module--testimonial_name--678YF";
export var testimonial_text = "testimonials-module--testimonial_text--CDoNS";
export var testimonials_section = "testimonials-module--testimonials_section--ADaHL";
export var title = "testimonials-module--title--VQwh-";